.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .slideshow-container {
    position: relative;
    width: 80%;
    height: 80%;
    min-width: 700px;
    min-height: 300px;
    overflow: hidden;
  }
}
