.navbar {
  position: fixed;
  background-color: rgb(18, 19, 24);
  width: 100%;
  height: 60px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    position: absolute;
    width: 40px;
    height: 40px;
    margin: auto;
  }
  .wrapper {
    position: absolute;
    right: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .navigation {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1rem;
      font-weight: normal;
      gap: 10px;

      .slider-bar {
        position: absolute;
        bottom: 25%;
        left: 0;
        width: 50px;
        height: 2px;
        background-color: rgb(140, 155, 210);
        border-radius: 1px;
      }

      .nav-item {
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: rgb(140, 155, 210);
          margin-right: 3px;
        }
      }
    }
  }
}
