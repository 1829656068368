.info {
  position: absolute;
  right: -35px;
  top: 0;
  cursor: pointer;
}

.modal {
  position: absolute;
  width: 250px;
  top: -4px;
  right: 10px;
  border-radius: 10px;
  border: 2px solid #8d8776;
  background-color: rgb(10, 11, 16);

  padding: 0.5rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .name {
    color: #f4ebd0;
    margin: 0.3rem;
    font-weight: bold;
  }

  .separator {
    width: 80%;
    height: 1px;
    background-color: #f4ebd0;
  }

  .text {
    color: #f4ebd0;
    margin: 0.3rem;
    font-size: 0.8rem;
    text-align: center;
  }
}
