.hero {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .hero-content {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    pointer-events: none;
  }

  span {
    display: block;
    color: rgb(140, 155, 210);
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }

  h1 {
    display: block;
    color: rgb(140, 188, 210);
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }

  h2 {
    display: block;
    color: rgb(140, 155, 210);
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.2rem;
  }

  p {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 10px;
  }
}
