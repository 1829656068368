.about {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .wrapper {
    width: 70%;
    height: 90%;
    min-width: 700px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-text {
      height: 100%;
      flex: 1;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        padding: 4rem;
        white-space: wrap;
        background-color: rgb(10, 11, 16);
        border-radius: 3rem;
      }
    }

    .game {
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
