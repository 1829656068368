.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .project-container {
    width: calc(100% - 8rem);
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      width: 100%;
      height: 20%;
      color: rgb(140, 155, 210);
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info {
      position: relative;
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    .img-container {
      position: absolute;
      height: 100%;
      aspect-ratio: 4/3;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 3px solid rgb(10, 11, 16);
        box-sizing: border-box;
        cursor: pointer;
      }
    }

    .text-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      max-width: 40%;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem 1rem 2rem;
      border-radius: 1.5rem;
      background-color: rgb(10, 11, 16);

      .text {
        color: #f4ebd0;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      .a-tag {
        display: inline-block;
        margin-bottom: 2rem;
      }

      .demo-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(140, 155, 210);
        border-radius: 0.7rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        cursor: pointer;
        color: black;
      }

      .technologies {
        position: absolute;
        width: 100%;
        bottom: 5px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 0.6rem;
        font-weight: bold;
        color: rgb(140, 155, 210);
      }
    }
  }
}

.button-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .button {
    position: absolute;
    cursor: pointer;
    pointer-events: all;
  }
}

.slider-centerer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .slider-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    bottom: 1rem;

    .slider {
      width: 5rem;
      height: 0.3rem;
      background-color: #f4ebd0;
      border-radius: 0.15rem;
      cursor: pointer;
    }
  }
}
