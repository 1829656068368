.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  .hero-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .hero-cell {
      background-color: rgb(30, 30, 32);
      border-left: 5px solid rgb(40, 40, 40);
      border-top: 5px solid rgb(40, 40, 40);
      border-bottom: 5px solid rgb(20, 20, 20);
      border-right: 5px solid rgb(20, 20, 20);
      pointer-events: none;
    }
  }
}
