.lights-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  .lights-cell {
    border-radius: 10px;
    cursor: pointer;
  }
}
