html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(9, 10, 15);
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(140, 155, 210);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(18, 19, 24);
  color: #f4ebd0;
  font-family: 'Poppins', sans-serif;

  overflow-x: hidden;
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  position: relative;
  overflow: hidden;
  height: 100vh;
  scroll-snap-align: start;
  background-color: rgb(18, 19, 24);
}
